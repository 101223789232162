import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../Source/styles/SourceTable.css';
import Pagination from '../Source/Pagination.jsx';

export default function SourceTable({
    initialSources,
    isLoaded
}) {
    const navigate = useNavigate();
    const [sources, setSources] = useState(initialSources);
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(15);

    useEffect(() => {
        setSources(initialSources);
    }, [initialSources]);

    const handleChangeRowsPerPage = (newRowsPerPage) => {
        setRowsPerPage(newRowsPerPage);
        setCurrentPage(0);
    };

    const handleOpenSource = (sourceId, sourceUrl) => {
        navigate('/auctions-to-parse', {
            state: {
                sourceId,
                sourceUrl
            }
        });
    };

    return (
        <div className="SourceTable">
            <table>
                <thead>
                    <tr>
                        <th className="id" style={{ textAlign: 'center', backgroundColor: '#fafafa' }}>Id</th>
                        <th className="title" style={{ backgroundColor: '#fafafa' }}>Title</th>
                        <th className="url" style={{ textAlign: 'center', backgroundColor: '#fafafa' }}>URL</th>
                    </tr>
                </thead>
                {isLoaded ? (
                    <tbody>
                        {sources.slice(
                            currentPage * rowsPerPage,
                            (currentPage + 1) * rowsPerPage
                        ).map((source) => (
                            <tr key={source.id}>
                                <td className="id" style={{ textAlign: 'center' }}>{source.id}</td>
                                <td className="title">
                                    <span
                                        onClick={() => handleOpenSource(source.id, source.url)}
                                        style={{ cursor: 'pointer', color: 'blue' }}>
                                        {source.name}
                                    </span>
                                </td>
                                <td className="url">
                                    <a href={source.url} target="_blank" rel="noopener noreferrer">{source.url}</a>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                ) : (
                    <div>Loading...</div>
                )}
            </table>

            <Pagination
                rowsPerPage={rowsPerPage}
                setPage={setCurrentPage}
                filteredAuctionsLength={sources.length}
                page={currentPage}
                handleChangeRowsPerPage={handleChangeRowsPerPage} />
        </div>
    );
}
