import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from '../Header/Header.jsx';
import "./styles/AuctionsToParse.css";

export default function AuctionsToParse() {
    const location = useLocation();
    const [formData, setFormData] = useState({
        xpath: "",
        url: "",
        date: "",
        title: "",
        saleNumber: "",
        location: "",
    });

    useEffect(() => {
        if (location.state) {
            setFormData((prevData) => ({
                ...prevData,
                url: location.state.sourceUrl || "",
            }));
        }
    }, [location.state]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    return (
        <>
            <Header />
            <div className="parsing-auction-container">
                <div className="parsing-auction-form">
                    <h2 className="parsing-auction-header">Auction Data Input</h2>
                    {[
                        { label: "Auctions XPath", name: "xpath" },
                        { label: "XPath URL", name: "url" },
                        { label: "XPath Date", name: "date" },
                        { label: "XPath Title", name: "title" },
                        { label: "XPath Sale Number", name: "saleNumber" },
                        { label: "XPath Location", name: "location" }
                    ].map((field) => (
                        <div key={field.name} className="parsing-auction-field">
                            <label className="parsing-auction-label" htmlFor={field.name}>
                                {field.label}
                            </label>
                            <input
                                id={field.name}
                                name={field.name}
                                type="text"
                                value={formData[field.name]}
                                onChange={handleChange}
                                className="parsing-auction-input"
                                placeholder={`Enter ${field.label.toLowerCase()}`}
                            />
                        </div>
                    ))}
                </div>

                <div className="parsing-auction-preview">
                    {formData.url ? (
                        <iframe
                            src={formData.url}
                            title="Auction Preview"
                            className="parsing-auction-iframe"
                        />
                    ) : (
                        <p className="parsing-auction-placeholder">
                            Enter a URL to preview the auction
                        </p>
                    )}
                </div>
            </div>
        </>
    );
}