import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import AuctionInfoContainer from './AuctionInfoContainer.jsx';
import JewelryInfoContainer from './JewelryInfoContainer.jsx';
import Frame from './Frame.jsx';
import './styles/Carousel.css';

async function fetchLotsIdsJewelryFixes(sourceId, auctionId, accessToken, setLotsWithFixes) {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_BASE_URL}/admin/sources/${sourceId}/auctions/${auctionId}/fixes`,
            {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            }
        );
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const fixesData = await response.json();
        setLotsWithFixes(fixesData);

    } catch (error) {
        console.error('Error fetching jewelry fixes:', error);
    }
}

async function fetchAuctionsJewelryFixes(sourceId, accessToken, setAuctionsWithFixes) {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_BASE_URL}/admin/sources/${sourceId}/fixes`,
            {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            }
        );
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const fixesData = await response.json();
        setAuctionsWithFixes(fixesData);
    } catch (error) {
        console.error('Error fetching jewelry fixes:', error);
    }
}

export default function Carousel(props) {
    const [currentItemIndex, setCurrentItemIndex] = useState(0);
    const [selectedLot, setSelectedLot] = useState(null);
    const [items, setItems] = useState([]);
    const [countItems, setCountItems] = useState(0);
    const accessToken = localStorage.getItem('tokenKey');
    const navigate = useNavigate();
    const [isFixed, setIsFixed] = useState(null);

    const id = props.id;
    const sourceId = props.sourceId;
    const auctions = props.auctions;
    const auctionsStyles = props.auctionsStyles;
    const foundAuction = auctions.find(auction => auction.id === id);
    const [selectedAuction, setSelectedAuction] = useState(foundAuction || auctions[0]);
    const [lotsWithFixes, setLotsWithFixes] = useState([]);
    const [auctionsWithFixes, setAuctionsWithFixes] = useState([]);
    const [detailItem, setDetailItem] = useState({
        estimatePriceFrom: '',
        estimatePriceTo: '',
        price: '',
        currency: '',
        url: '',
        photos: []
    });

    const createRequestOptions = (accessToken) => ({
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        }
    });

    const requestOptions = useMemo(() => createRequestOptions(accessToken), [accessToken]);
    useEffect(() => {
        if (!accessToken) {
            navigate('/login');
        }

        fetch(`${process.env.REACT_APP_BASE_URL}/admin/sources/${sourceId}/auctions/${encodeURIComponent(id)}/jewelries`, requestOptions)
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        navigate('/login');
                        return {};
                    }
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                const itemsFromResponse = Object.values(data);
                let countItems = itemsFromResponse.length;
                if (countItems !== 0) {
                    setItems(itemsFromResponse);
                    setCountItems(countItems);
                    const firstItem = itemsFromResponse[0];
                    setSelectedLot({ value: firstItem.id, label: firstItem.id.toString() });
                    setDetailItem({ url: firstItem.url || '' });
                    setCurrentItemIndex(0);
                    //fetchLotsIdsJewelryFixes(sourceId, id, accessToken, setLotsWithFixes);
                    //setAuctionsWithFixes(auctionsStyles);
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    // eslint-disable-next-line
    }, [sourceId, id, navigate, accessToken]);

    useEffect(() => {
        const foundAuction = auctions.find(auction => auction.id === props.id);
        if (foundAuction) {
            setSelectedAuction(foundAuction);
        }
    }, [props.id, auctions]);

    const fetchNewLots = (auctionId) => {
        setItems([]);
        setSelectedLot(null);
        setDetailItem({
            estimatePriceFrom: '',
            estimatePriceTo: '',
            openingBid: '',
            currentBid: '',
            price: '',
            currency: '',
            url: '',
            photos: []
        });
        setCurrentItemIndex(0);

        fetch(`${process.env.REACT_APP_BASE_URL}/admin/sources/${sourceId}/auctions/${encodeURIComponent(auctionId)}/jewelries`, requestOptions)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                const itemsFromResponse = Object.values(data);
                let countItems = itemsFromResponse.length;
                if (countItems > 0) {
                    setItems(itemsFromResponse);
                    setCountItems(countItems);
                    const firstItem = itemsFromResponse[0];
                    setSelectedLot({ value: firstItem.id, label: firstItem.id.toString() });
                    setDetailItem({
                        estimatePriceFrom: firstItem.estimatePriceFrom || '',
                        estimatePriceTo: firstItem.estimatePriceTo || '',
                        price: firstItem.price || '',
                        openingBid: firstItem.openingBid || '',
                        currentBid: firstItem.currentBid || '',
                        currency: firstItem.currency || '',
                        url: firstItem.url || '',
                        photos: (firstItem.photosRaw && firstItem.photosRaw.split(',')) || []
                    });
                    setCurrentItemIndex(0);
                    //fetchLotsIdsJewelryFixes(sourceId, auctionId, accessToken, setLotsWithFixes);
                    //fetchAuctionsJewelryFixes(sourceId, accessToken, setAuctionsWithFixes);
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };

    const updateDetailItem = useCallback((index) => {
        if (index >= 0 && index < items.length) {
            const newDetailItem = items[index];
            setDetailItem({
                estimatePriceFrom: newDetailItem.estimatePriceFrom || '0',
                estimatePriceTo: newDetailItem.estimatePriceTo || '0',
                price: newDetailItem.price || '0',
                openingBid: newDetailItem.openingBid || '0',
                currentBid: newDetailItem.currentBid || '0',
                currency: newDetailItem.currency || '',
                url: newDetailItem.url || '',
                photos: (newDetailItem.photosRaw && newDetailItem.photosRaw.split(',')) || []
            });
        }
    }, [items]);

    const changeItem = useCallback((direction) => {
        setCurrentItemIndex((prevIndex) => {
            let newIndex = prevIndex;
            if (direction === 'prev') {
                newIndex = (prevIndex - 1 + items.length) % items.length;
            } else if (direction === 'next') {
                newIndex = (prevIndex + 1) % items.length;
            }
            const newLotId = items[newIndex].id;

            fetchJewelryDetails(sourceId, newLotId, accessToken).then(newDetailItem => {
                setDetailItem(newDetailItem);
                setSelectedLot({ value: newLotId, label: newLotId.toString() });
            }).catch(error => console.error('Error fetching jewelry details:', error));

            return newIndex;
        });
    // eslint-disable-next-line
    }, [items, sourceId, accessToken]);

    async function fetchJewelryDetails(sourceId, lotId, accessToken) {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/admin/sources/${sourceId}/auctions/${selectedAuction.id}/jewelries/${lotId}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            }
            );
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const detailsData = await response.json();
            return detailsData;
        } catch (error) {
            console.error('Error fetching jewelry details:', error);
            throw error;
        }
    }

    const handleLotChange = (selectedOption) => {
        const newIndex = items.findIndex(item => item.id.toString() === selectedOption.value);
        if (newIndex !== -1) {
            setCurrentItemIndex(newIndex);
            setSelectedLot(selectedOption);
            updateDetailItem(newIndex);
        }
    };

    const handleJewelryUrlClick = (url) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const handleAuctionChange = useCallback((newAuction) => {
        setSelectedAuction(newAuction);
        fetchNewLots(newAuction.id);
    // eslint-disable-next-line
    }, []);

    const handleItemDeleted = useCallback((deletedItemId) => {
        const index = items.findIndex(item => item.id === deletedItemId);
        let newIndex = index;

        if (index === items.length - 1) {
            newIndex = index - 1;
        } else if (index < items.length - 1) {
            newIndex = index;
        }

        const newItems = items.filter(item => item.id !== deletedItemId);
        setItems(newItems);
        setCountItems(newItems.length);

        if (newItems.length > 0) {
            setSelectedLot({ value: newItems[newIndex].id, label: newItems[newIndex].id.toString() });
            setDetailItem(newItems[newIndex]);
            setCurrentItemIndex(newIndex);
        } else {
            setSelectedLot(null);
            setDetailItem({});
            setCurrentItemIndex(0);
        }
    }, [items]);

    return (
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(1, 1fr)' }}>
            <div className="main-container">
                <div className="auction-info-sidebar">
                    <AuctionInfoContainer
                        items={items}
                        countItems={countItems}
                        auctions={auctions}
                        selectedAuction={selectedAuction}
                        handleAuctionChange={handleAuctionChange}
                        handleLotChange={handleLotChange}
                        selectedLot={selectedLot}
                        setSelectedLot={setSelectedLot}
                        currentItemIndex={currentItemIndex}
                        detailItem={detailItem}
                        setDetailItem={setDetailItem}
                        handleJewelryUrlClick={handleJewelryUrlClick}
                        changeItem={changeItem}
                        lotsWithFixes={lotsWithFixes}
                        auctionsWithFixes={auctionsWithFixes}
                        isFixed={isFixed} />
                </div>
                <div className="jewelry-info-main">
                    <JewelryInfoContainer
                        sourceId={sourceId}
                        auctionId={selectedAuction?.id || 0}
                        itemId={(items[currentItemIndex] || {}).id || 0}
                        onItemDeleted={handleItemDeleted}
                        detailItem={detailItem}
                        setIsFixed={setIsFixed} />
                </div>
                <Frame item={(items[currentItemIndex] || {}).url || ''} />
            </div>
        </div>
    );
};