import React, { useState, useEffect } from 'react';
import AddNewItemDialog from '../Dialogs/AddNewItemDialog.jsx';
import AddTypesDialog from '../Dialogs/AddTypesDialog.jsx';
import DetailItem from './DetailItem.jsx';
import CategoryDetailItem from './CategoryDetailItem.jsx';
import GenderDetailItem from './GenderDetailItem.jsx';
import ManufacturerDetailItem from './ManufacturerDetailItem.jsx';
import './styles/JewelryInfoContainer.css';

export default function JewelryInfoContainer({
    sourceId,
    auctionId,
    itemId,
    detailItem,
    onItemDeleted,
    setIsFixed
}) {
    const [itemDetails, setItemDetails] = useState({});
    const [endpoint, setEndpoint] = useState('');
    const [showAddNewItemDialog, setShowAddNewItemDialog] = useState(false);
    const [showAddTypesDialog, setShowAddTypesDialog] = useState(false);
    const [jewelryFix, setJewelryFix] = useState({});
    const accessToken = localStorage.getItem('tokenKey');

    useEffect(() => {
        const fetchItemDetails = async () => {
            const headers = new Headers({
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            });

            try {
                const response = await fetch(`${process.env.REACT_APP_BASE_URL}/admin/sources/${sourceId}/auctions/${auctionId}/jewelries/${itemId}`, { headers });
                if (!response.ok) throw new Error('Network response was not ok');

                const itemData = await response.json();
                setItemDetails(itemData);
            } catch (error) {
                console.error('Error fetching item details:', error);
            }
        };

        if (itemId) fetchItemDetails();
    }, [sourceId, auctionId, itemId, accessToken]);

	// eslint-disable-next-line
    useEffect(() => {
        const fetchJewelryFix = async () => {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_BASE_URL}/admin/sources/${sourceId}/auctions/${auctionId}/jewelries/${itemDetails.id}/fixes`,
                    {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    }
                );

                if (response.status === 404) {
                    setJewelryFix({});
                    setIsFixed(null);
                    return;
                }

                if (!response.ok) {
                    setJewelryFix({});
                    setIsFixed(null);
                    return;
                }

                const fixData = await response.json();
                if (fixData) {
                    setJewelryFix(fixData);
                } else {
                    setJewelryFix({});
                }

                setIsFixed(jewelryFix == null ? null : jewelryFix.isFixed);
            } catch (error) {
                setJewelryFix({});
                setIsFixed(null);
                console.error('Error fetching jewelry fix:', error);
            }
        };

        //if (itemDetails.id) {
        //    fetchJewelryFix();
        //}
    // eslint-disable-next-line
    }, [sourceId, auctionId, itemDetails, accessToken, jewelryFix?.isFixed, setIsFixed]);

    const addOrUpdateJewelryFix = () => {
        const url = `${process.env.REACT_APP_BASE_URL}/admin/jewelries/fixes`;
        const jewelryFixData = {
            sourceId: sourceId,
            auctionId: auctionId,
            id: itemDetails.id,
            dimensions: jewelryFix.dimensions,
            condition: jewelryFix.condition,
            provenance: jewelryFix.provenance,
            created: jewelryFix.created,
            category: jewelryFix.category,
            gemstone: jewelryFix.gemstone,
            diamondColor: jewelryFix.diamondColor,
            grossWeight: jewelryFix.grossWeight,
            stoneWeight: jewelryFix.stoneWeight,
            material: jewelryFix.material,
            gender: jewelryFix.gender,
            manufacturer: jewelryFix.manufacturer,
            type: jewelryFix.type,
            referenceNumber: jewelryFix.referenceNumber,
            claspOrBuckle: jewelryFix.claspOrBuckle,
            clarity: jewelryFix.clarity,
            weave: jewelryFix.weave,
            motif: jewelryFix.motif,
            style: jewelryFix.style,
            isFixed: false
        };

        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(jewelryFixData)
        };

        fetch(url, requestOptions)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setIsFixed(false);
            })
            .catch(error => {
                console.error('Error adding jewelry fix:', error);
            });
    };

    const updateJewelry = () => {
        const url = `${process.env.REACT_APP_BASE_URL}/admin/sources/jewelries`;
        const jewelry = {
            sourceId: sourceId,
            auctionId: auctionId,
            id: itemDetails.id,
            title: itemDetails.title,
            description: itemDetails.description,
            dimensions: itemDetails.dimensions,
            condition: itemDetails.condition,
            estimatePriceFrom: detailItem.estimatePriceFrom,
            estimatePriceTo: detailItem.estimatePriceTo,
            openingBid: detailItem.openingBid,
            currentBid: detailItem.currentBid,
            price: detailItem.price,
            provenance: itemDetails.provenance,
            created: itemDetails.created,
            category: itemDetails.category,
            gemstone: itemDetails.gemstone,
            diamondColor: itemDetails.diamondColor,
            grossWeight: itemDetails.grossWeight,
            stoneWeight: itemDetails.stoneWeight,
            material: itemDetails.material,
            gender: itemDetails.gender,
            manufacturer: itemDetails.manufacturer,
            type: itemDetails.type,
            referenceNumber: itemDetails.referenceNumber,
            claspOrBuckle: itemDetails.claspOrBuckle,
            clarity: itemDetails.clarity,
            weave: itemDetails.weave,
            motif: itemDetails.motif,
            style: itemDetails.style
        };

        const requestOptions = {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(jewelry)
        };

        fetch(url, requestOptions)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setIsFixed(false);
            })
            .catch(error => {
                console.error('Error adding jewelry fix:', error);
            });
    };

    const markJewelryAsFixed = () => {
        if (jewelryFix.id !== itemDetails.id)
            return;

        const jewelryFixData = {
            sourceId: sourceId,
            auctionId: auctionId,
            id: itemDetails.id
        };

        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(jewelryFixData)
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/admin/jewelries/fix`, requestOptions)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setIsFixed(true);
            })
            .catch(error => {
                console.error('Error updating jewelry fix:', error);
            });
    };

    const markJewelryAsDeleted = () => {
        const jewelryFixData = {
            sourceId: sourceId,
            auctionId: auctionId,
            id: itemDetails.id
        };

        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(jewelryFixData)
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/admin/sources/${sourceId}/auctions/${auctionId}/jewelries/${itemDetails.id}`, requestOptions)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setIsFixed(jewelryFix?.isFixed);
            })
            .catch(error => {
                console.error('Error updating jewelry fix:', error);
            });

        onItemDeleted(itemDetails.id);
    };

    const handleChange = (key, value) => {
        setItemDetails(prevDetails => ({
            ...prevDetails,
            [key]: value
        }));
    };

    const handleAddClick = (newEndpoint) => {
        setShowAddNewItemDialog(true);
        setEndpoint(newEndpoint);
    };

    const handleAddTypesClick = (newEndpoint) => {
        setShowAddTypesDialog(true);
        setEndpoint(newEndpoint);
    };

    const handleCloseDialog = () => {
        setShowAddNewItemDialog(false);
        setShowAddTypesDialog(false);
    };

    return (
        <div className="jewelry-main">
            <div className="detail-item">
                <span className="detail-label">Title:</span>
                <input
                    value={itemDetails.title || ''}
                    onChange={(e) => setItemDetails({ ...itemDetails, title: e.target.value })} />
            </div>
            <CategoryDetailItem
                label="Category"
                value={itemDetails.category || ''}
                fixValue={jewelryFix.category || ''}
                onValueChange={(e) => handleChange('category', e.target.value)}
                onFixValueChange={(e) => setJewelryFix({ ...jewelryFix, category: e.target.value })} />
            <DetailItem
                label="Type"
                value={itemDetails.type || ''}
                fixValue={jewelryFix.type || ''}
                onValueChange={(e) => handleChange('type', e.target.value)}
                onFixValueChange={(e) => setJewelryFix({ ...jewelryFix, type: e.target.value })}
                onAddClick={() => handleAddTypesClick("types")} />
            <ManufacturerDetailItem
                label="Manufacturer"
                value={itemDetails.manufacturer || ''}
                onValueChange={(e) => handleChange('manufacturer', e.target.value)}
                onAddClick={() => handleAddClick("manufacturers")} />
            <DetailItem
                label="Clarity"
                value={itemDetails.clarity || ''}
                fixValue={jewelryFix.clarity || ''}
                onValueChange={(e) => handleChange('clarity', e.target.value)}
                onFixValueChange={(e) => setJewelryFix({ ...jewelryFix, clarity: e.target.value })} />
            <DetailItem
                label="Diamond color"
                value={itemDetails.diamondColor || ''}
                fixValue={jewelryFix.diamondColor || ''}
                onValueChange={(e) => handleChange('diamondColor', e.target.value)}
                onFixValueChange={(e) => setJewelryFix({ ...jewelryFix, diamondColor: e.target.value })} />
            <DetailItem
                label="Buckle"
                value={itemDetails.claspOrBuckle || ''}
                fixValue={jewelryFix.claspOrBuckle || ''}
                onValueChange={(e) => handleChange('claspOrBuckle', e.target.value)}
                onFixValueChange={(e) => setJewelryFix({ ...jewelryFix, claspOrBuckle: e.target.value })}
                onAddClick={() => handleAddClick("claspOrBuckles")} />
            <DetailItem
                label="Gemstone"
                value={itemDetails.gemstone || ''}
                fixValue={jewelryFix.gemstone || ''}
                onValueChange={(e) => handleChange('gemstone', e.target.value)}
                onFixValueChange={(e) => setJewelryFix({ ...jewelryFix, gemstone: e.target.value })}
                onAddClick={() => handleAddClick("gemstones")} />
            <DetailItem
                label="Material"
                value={itemDetails.material || ''}
                fixValue={jewelryFix.material || ''}
                onValueChange={(e) => handleChange('material', e.target.value)}
                onFixValueChange={(e) => setJewelryFix({ ...jewelryFix, material: e.target.value })}
                onAddClick={() => handleAddClick("strongMaterials")} />
            <DetailItem
                label="Weave"
                value={itemDetails.weave || ''}
                fixValue={jewelryFix.weave || ''}
                onValueChange={(e) => handleChange('weave', e.target.value)}
                onFixValueChange={(e) => setJewelryFix({ ...jewelryFix, weave: e.target.value })}
                onAddClick={() => handleAddClick("weaves")} />
            <DetailItem
                label="Motif"
                value={itemDetails.motif || ''}
                fixValue={jewelryFix.motif || ''}
                onValueChange={(e) => handleChange('motif', e.target.value)}
                onFixValueChange={(e) => setJewelryFix({ ...jewelryFix, motif: e.target.value })}
                onAddClick={() => handleAddClick("motifs")} />
            <DetailItem
                label="Style"
                value={itemDetails.style || ''}
                fixValue={jewelryFix.style || ''}
                onValueChange={(e) => handleChange('style', e.target.value)}
                onFixValueChange={(e) => setJewelryFix({ ...jewelryFix, style: e.target.value })}
                onAddClick={() => handleAddClick("styles")} />
            <DetailItem
                label="Ref"
                value={itemDetails.referenceNumber || ''}
                fixValue={jewelryFix.referenceNumber || ''}
                onValueChange={(e) => handleChange('referenceNumber', e.target.value)}
                onFixValueChange={(e) => setJewelryFix({ ...jewelryFix, referenceNumber: e.target.value })} />
            <DetailItem
                label="Created"
                value={itemDetails.created || ''}
                fixValue={jewelryFix.created || ''}
                onValueChange={(e) => handleChange('created', e.target.value)}
                onFixValueChange={(e) => setJewelryFix({ ...jewelryFix, created: e.target.value })} />
            <DetailItem
                label="Dimensions"
                value={itemDetails.dimensions || ''}
                fixValue={jewelryFix.dimensions || ''}
                onValueChange={(e) => handleChange('dimensions', e.target.value)}
                onFixValueChange={(e) => setJewelryFix({ ...jewelryFix, dimensions: e.target.value })} />
            <GenderDetailItem
                label="Gender"
                value={itemDetails.gender || ''}
                fixValue={jewelryFix.gender || ''}
                onValueChange={(e) => handleChange('gender', e.target.value)}
                onFixValueChange={(e) => setJewelryFix({ ...jewelryFix, gender: e.target.value })} />
            <DetailItem
                label="Gross"
                value={itemDetails.grossWeight || ''}
                fixValue={jewelryFix.grossWeight || ''}
                onValueChange={(e) => handleChange('grossWeight', e.target.value)}
                onFixValueChange={(e) => setJewelryFix({ ...jewelryFix, grossWeight: e.target.value })} />
            <DetailItem
                label="Stone"
                value={itemDetails.stoneWeight || ''}
                fixValue={jewelryFix.stoneWeight || ''}
                onValueChange={(e) => handleChange('stoneWeight', e.target.value)}
                onFixValueChange={(e) => setJewelryFix({ ...jewelryFix, stoneWeight: e.target.value })} />
            <DetailItem
                label="Condition"
                value={itemDetails.condition || ''}
                fixValue={jewelryFix.condition || ''}
                onValueChange={(e) => handleChange('condition', e.target.value)}
                onFixValueChange={(e) => setJewelryFix({ ...jewelryFix, condition: e.target.value })}
                onAddClick={() => handleAddClick("conditions")} />
            <DetailItem
                label="Provenance"
                value={itemDetails.provenance || ''}
                fixValue={jewelryFix.provenance || ''}
                onValueChange={(e) => handleChange('provenance', e.target.value)}
                onFixValueChange={(e) => setJewelryFix({ ...jewelryFix, provenance: e.target.value })}
                onFixClick={() => addOrUpdateJewelryFix('provenance')} />
            <div style={{ alignItems: 'start', marginBottom: '0', paddingBottom: '0' }} className="detail-item">
                <span className="detail-label">Descrip:</span>
                <textarea
                    style={{ fontFamily: 'Arial, sans-serif' }}
                    value={itemDetails.description || ''}
                    onChange={(e) => setItemDetails({ ...itemDetails, description: e.target.value })}
                />
            </div>

            <div className="update-button-container">
                <button type="button" className="fix-button delete-item-button" onClick={() => markJewelryAsDeleted()}>Delete</button>
                <button type="button" className="fix-button" onClick={() => updateJewelry()}>Update</button>
            <button type="button" className="fix-button" onClick={() => addOrUpdateJewelryFix()}>Update fixes</button>
                <button type="button" className="fix-button approve-item-button" onClick={() => markJewelryAsFixed()}>Approve</button>
            </div>

            {showAddNewItemDialog && (
                <AddNewItemDialog title="Title of the Window" onClose={handleCloseDialog} endpoint={endpoint} />
            )}
            {showAddTypesDialog && (
                <AddTypesDialog title="Title of the Window" onClose={handleCloseDialog} endpoint={endpoint} />
            )}
        </div>
    );
}