import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Header from '../Header/Header.jsx';
import SourceTableToParse from './SourceTableToParse.jsx';

export default function SourcesToParse() {
    const [sources, setSources] = useState([]);

    const [isLoaded, setIsLoaded] = useState(false);
    const navigate = useNavigate();

    const fetchSources = async (url) => {
        setIsLoaded(false);
        const accessToken = localStorage.getItem('tokenKey');
        if (!accessToken) {
            navigate('/login');
            return;
        }
        try {
            const response = await axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            });
            setSources(response.data);
        } catch (error) {
            if (error.response.status === 401 || error.response.status === 403) {
                navigate('/login');
            }
        } finally {
            setIsLoaded(true);
        }
    };

    useEffect(() => {
        let url;

        url = `http://localhost:8081/api/admin/sources-to-parse`;
        //url = `${process.env.REACT_APP_BASE_URL}/admin/sources-to-parse`;

        fetchSources(url);

    }, [navigate]);

    return (
        <>
            <Header />
            <SourceTableToParse
                initialSources={sources}
                isLoaded={isLoaded}
            />
        </>
    );
}